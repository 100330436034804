import * as Sentry from '@sentry/react';
import api from 'api';
import { UserSerializer } from 'api/Serializers/Users';
import { HTTP_401_UNAUTHORIZED } from 'api/status';
import urls from 'api/urls';
import axios, { AxiosError } from 'axios';
import {
  ACCESS_TOKEN_LIFETIME_MINS,
  ACCESS_TOKEN_REFRESH_THRESHOLD_MINS,
  API_ROOT_URL,
  APP_RELEASE,
  CLIENT_BEAMER_KEY,
  DATE_FMT,
  INSTRUCTOR_BEAMER_KEY,
  IS_SERVER,
  MAX_NETWORK_RETRIES,
  NETWORK_RETRIES_BASE_DELAY_MS,
  NETWORK_RETRIES_INCREASE_DELAY_MS,
  SentryTag,
  TOKEN_PREFIX,
  UserType,
} from 'config';
import { SessionExpired } from 'lang/en/Snackbars';
import { messagingClientManager } from 'messagingClientManager';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getIsIdentified, getUser } from 'state/selectors';
import {
  fetchAccountCredit,
  fetchAccountParticipants,
  fetchCancellationMetrics,
  fetchEarlyAccessWindows,
  fetchEarnings,
  fetchNotices,
  retrieveAccount,
  setProxy,
} from 'state/slice/account';
import { networkError, networkSuccess } from 'state/slice/app';
import { fetchClientAppointments } from 'state/slice/appointments';
import {
  logUserOut,
  saveAuthTokens,
  setIsIdentified,
  setUserDataLoaded,
  TokenStatus,
} from 'state/slice/authentication';
import { fetchClients } from 'state/slice/clients';
import { fetchSchedulableFacilities } from 'state/slice/facility';
import { fetchFavourites } from 'state/slice/favourites';
import { fetchScheduleInstructors } from 'state/slice/instructor';
import { fetchProposals } from 'state/slice/proposals';
import {
  fetchAppointmentProducts,
  setScheduleFilterDate,
  setScheduleRenderDate,
} from 'state/slice/schedule';
import { CookieStore } from 'state/storage';
import { CookieKey } from 'state/storage/cookies';
import { inProxySession, tokenDecode } from 'utils/auth';
import { useAppDispatch } from './useAppDispatch';
import { usePostHog } from './usePostHog';

function axiosRequestInterceptor(request) {
  logger.log('Axios: onRequest', request.url);
  if (request?.headers) {
    if (request?.url.indexOf(API_ROOT_URL) !== 0) {
      // Is 3rd party site, do not send token/device/session ids
      request.headers.common.Authorization = null;
    } else {
      let token = CookieStore.get(CookieKey.AuthToken);
      if (getTokenStatus(token) !== 'invalid') {
        request.headers.Authorization = `${TOKEN_PREFIX} ${token}`;
      }
    }
  }
  return request;
}

const initBeamer = ({
  id,
  firstName,
  lastName,
  email,
  type,
}: UserSerializer) => {
  if (
    IS_SERVER ||
    window.beamer_config === undefined ||
    window.beamer_config.is_initialized === true
  ) {
    return;
  }

  const product_id =
    type === UserType.Client
      ? CLIENT_BEAMER_KEY
      : type === UserType.Instructor || type === UserType.Admin
      ? INSTRUCTOR_BEAMER_KEY
      : '';
  const config: BeamerConfig = {
    ...window.beamer_config,
    user_id: String(id),
    user_firstname: firstName,
    user_lastname: lastName,
    user_email: email,
    selector: 'beamer-link',
    product_id,
    lazy: true,
    right: 5,
    is_initialized: true,
  };
  window.beamer_config = config;
};

function initAxios(onSuccess, onResponseError) {
  axios.defaults.headers.get['Content-Type'] = `application/json`;
  axios.defaults.headers.put['Content-Type'] = `application/json`;
  axios.defaults.headers.post['Content-Type'] = `application/json`;
  axios.defaults.headers.patch['Content-Type'] = `application/json`;
  axios.defaults.headers.common['Content-Type'] = `application/json`;
  axios.defaults.headers.delete['Content-Type'] = `application/json`;
  if (axios.interceptors.request) {
    axios.interceptors.request.clear();
  }
  if (axios.interceptors.response) {
    axios.interceptors.response.clear();
  }
  axios.interceptors.request.use(axiosRequestInterceptor);
  axios.interceptors.response.use(onSuccess, onResponseError);
}

export async function getFreshToken() {
  let token = CookieStore.get(CookieKey.AuthToken);
  const status = getTokenStatus(token);
  if (status === 'invalid') {
    return false;
  } else if (status === 'expiring') {
    let refresh = CookieStore.get(CookieKey.RefreshToken);
    const response = await api.auth.refresh(refresh);
    token = response.data.token;
    saveAuthTokens(token, refresh, inProxySession());
  }
  return true;
}

function getTokenStatus(token: string): TokenStatus {
  if (!token) {
    return 'invalid';
  }
  const decoded = tokenDecode(token);
  const tokenExpiry = moment(decoded.exp, DATE_FMT.UNIX_SEC);
  const diff = tokenExpiry.diff(moment(), 'minutes');
  return diff < ACCESS_TOKEN_REFRESH_THRESHOLD_MINS ? 'expiring' : 'valid';
}

const useSession = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { isPostHogReady } = usePostHog();
  const { enqueueSnackbar } = useSnackbar();
  const [init, setInit] = useState(false);
  const isIdentified = useSelector(getIsIdentified);
  const [refreshInterval, setRefreshInterval] = useState(undefined);
  const token = CookieStore.get(CookieKey.AuthToken);
  const user = useSelector(getUser);

  function handleSuccess(response) {
    if (response && response.config && response.config.headers.RetryAttempt) {
      dispatch(networkSuccess());
    }
    return response;
  }

  async function handleError(error: AxiosError) {
    if (!error?.isAxiosError) {
      // Not an axios or network issue, log it
      if (error?.message) {
        logger.error(error.message, error);
      } else {
        logger.error(error);
      }
      return Promise.reject(error);
    }
    const prevConfig = {
      ...error.config,
      headers: { ...error.config.headers },
    };
    if (error.code === AxiosError.ERR_CANCELED) {
      // Calls that use AbortController should expect to
      // handle cancellation exceptions themselves in a try/catch
      // using catch(error) { if(axios.isCancel(error)) {return} }
      return Promise.reject(error);
    } else if (error.code === AxiosError.ERR_BAD_REQUEST) {
      if (error.response?.status === HTTP_401_UNAUTHORIZED) {
        if (error.config.url.indexOf(urls.auth.token) > -1) {
          return Promise.reject(error);
        }
        if (user?.id) {
          enqueueSnackbar(SessionExpired);
        }
        dispatch(logUserOut);
        return error;
      }
      logger.captureAxiosError(`Bad Request ${error.message}`, error);
      return Promise.reject(error);
    } else if (error.code === AxiosError.ERR_BAD_RESPONSE) {
      logger.captureAxiosError(`Bad Response ${error.message}`, error);
      return Promise.reject(error);
    } else if (error.code === AxiosError.ERR_NETWORK) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      const retryAttempts = Number(prevConfig.headers.RetryAttempt) || 0;
      dispatch(networkError({ retryAttempts }));
      if (retryAttempts === MAX_NETWORK_RETRIES) {
        logger.error(error.message, { ...prevConfig });
        return Promise.reject(error);
      } else {
        return new Promise((resolve) =>
          setTimeout(() => {
            prevConfig.headers.RetryAttempt = String(retryAttempts + 1);
            return resolve(axios(prevConfig));
          }, NETWORK_RETRIES_BASE_DELAY_MS + retryAttempts * NETWORK_RETRIES_INCREASE_DELAY_MS)
        );
      }
    } else {
      // Something happened in setting up the request that triggered an Error
      logger.error(error.message, { error, ...prevConfig });
      return Promise.reject(error);
    }
  }

  const initPropel = () => {
    dispatch(setScheduleRenderDate(moment().format(DATE_FMT.DATE_KEY)));
    dispatch(setScheduleFilterDate(moment().format(DATE_FMT.DATE_KEY)));
    if (!CookieStore.isEnabled()) {
      enqueueSnackbar({
        message:
          'Your browser cookies are disabled which will disable some features',
        variant: 'warning',
      });
    }
    Sentry.configureScope(function (scope) {
      scope.setTag(SentryTag.Environment, 'react-client');
      scope.setTag(SentryTag.DeviceId, CookieStore.get(CookieKey.DeviceId));
      scope.setTag(SentryTag.SessionId, CookieStore.get(CookieKey.SessionId));
    });
    // Show proxy ui notices
    if (inProxySession()) {
      dispatch(setProxy(true));
    }
  };

  const validateToken = async () => {
    getFreshToken();
    if (!init) {
      setInit(true);
    }
  };

  const fetchUserDetails = async (user: UserSerializer) => {
    if (user.type === UserType.Instructor) {
      await Promise.all([
        dispatch(retrieveAccount(user.username)),
        dispatch(fetchClients()),
        dispatch(fetchSchedulableFacilities()),
        dispatch(fetchAppointmentProducts()),
        dispatch(fetchEarlyAccessWindows()),
        dispatch(fetchCancellationMetrics()),
        dispatch(fetchEarnings()),
      ]);
    } else if (user.type === UserType.Admin) {
      await Promise.all([
        dispatch(retrieveAccount(user.username)),
        dispatch(fetchScheduleInstructors()),
        dispatch(fetchSchedulableFacilities()),
      ]);
    } else if (user.type === UserType.Host) {
      await Promise.all([
        dispatch(retrieveAccount(user.username)),
        dispatch(fetchScheduleInstructors()),
        dispatch(fetchSchedulableFacilities()),
        dispatch(fetchClients()),
      ]);
    } else if (user.type === UserType.Client) {
      await Promise.all([
        dispatch(retrieveAccount(user.username)),
        dispatch(fetchNotices()),
        dispatch(fetchProposals()),
        dispatch(fetchClientAppointments()),
        dispatch(fetchScheduleInstructors()),
        dispatch(fetchSchedulableFacilities()),
        dispatch(fetchFavourites()),
        dispatch(fetchAccountParticipants),
        dispatch(fetchAccountCredit()),
      ]);
    }
    dispatch(setUserDataLoaded(true));
  };

  if (!init) {
    initAxios(handleSuccess, handleError);
  }

  useEffect(() => {
    initPropel();
    validateToken();
  }, []);

  useEffect(() => {
    if (token && !refreshInterval) {
      setRefreshInterval(
        setInterval(
          validateToken,
          ACCESS_TOKEN_LIFETIME_MINS * 60 * 1000 * 0.75
        )
      );
      window.addEventListener('focus', validateToken);
    } else if (!token && refreshInterval) {
      clearInterval(refreshInterval);
      setRefreshInterval(undefined);
      window.removeEventListener('focus', validateToken);
    }
  }, [token]);

  useEffect(() => {
    if (!init) {
      return;
    }
    if (user !== undefined) {
      if (!isIdentified) {
        if (!inProxySession()) {
          Sentry.setUser({
            username: user.username,
            email: user.email,
            release: APP_RELEASE,
          });
          rudderanalytics.identify(user.email, { ...user });
          rudderanalytics.group(user.type, { groupType: 'user_type' });
          dispatch(setIsIdentified(true));
        }
        fetchUserDetails(user);
        initBeamer(user);
        messagingClientManager.startClient();
      }
    } else {
      Sentry.configureScope((scope) => {
        scope.setUser(null);
      });
      rudderanalytics.reset();
      dispatch(setIsIdentified(false));
      messagingClientManager.logout();
    }
  }, [init, user]);

  useEffect(() => {
    if (!inProxySession()) {
      rudderanalytics.page();
    }
  }, [location]);

  return null;
};

export default useSession;
