import { ListedStatuses } from 'api/Serializers/Accounts';
import { FETCH_STATE, UserType } from 'config';
import { RootState } from 'state';

// ADMIN
export const getAdminFetchState = (state: RootState) =>
  state.admin.kpis.fetchState;
export const getAdminKpi = (state: RootState) => state.admin.kpis.data;
export const getRelease = (state: RootState) => state.app.release;
// UI
/** state.app.cities */
export const getAppNewsFeedState = (state: RootState) =>
  state.app.newsFeedState;
export const getAppNetworkRetry = (state: RootState) => state.app.networkRetry;

// Account
export const getAccountNotices = (state: RootState) =>
  state.account.notices.data;
export const getAccountNoticesFetchState = (state: RootState) =>
  state.account.notices.fetchState;
export const getAccountDetail = (state: RootState) => state.account.detail;
export const getAccountEarnings = (state: RootState) =>
  state.account.earnings.data;
export const getAccountEarningsFetchState = (state: RootState) =>
  state.account.earnings.fetchState;
export const getAccountCancellationMetrics = (state: RootState) =>
  state.account.cancellationMetrics.data;
export const getAccountCancellationMetricsFetchState = (state: RootState) =>
  state.account.cancellationMetrics.fetchState;
export const getAccountCredit = (state: RootState) => state.account.credit;
export const getAccountFetchState = (state: RootState) =>
  state.account.fetchState;
export const getAccountParticipants = (state: RootState) =>
  state.account.participants;
export const getAccountIsLoaded = (state: RootState) => state.account.isLoaded;
export const getCurrentUser = (state: RootState) => state.account.detail;
export const getProxy = (state: RootState) => state.account.proxy;
export const getEarlyAccessWindows = (state: RootState) =>
  state.account.earlyAccessWindows;
export const getContacts = (state: RootState) => state.account.contacts.data;
export const getContactsFetchState = (state: RootState) =>
  state.account.contacts.fetchState;
export const getContactsFetchedAt = (state: RootState) =>
  state.account.contacts.fetchedAt;

// Authentication
export const getUser = (state: RootState) => state.authentication.user;
export const getUserType = (state: RootState) =>
  state.authentication.user?.type ?? UserType.Anonymous;
export const getUsername = (state: RootState) => state.authentication.username;
export const getIsUserDataLoaded = (state: RootState) =>
  state.authentication.isUserDataLoaded;
export const getIsAuthenticated = (state: RootState) =>
  state.authentication.isAuthenticated;
export const getIsIdentified = (state: RootState) =>
  state.authentication.isIdentified;
export const getAuthenticationFetchState = (state: RootState) =>
  state.authentication.fetchState;

// Availability
export const getAvailability = (state: RootState) => state.availability.list;
export const getAvailabilityFetchState = (state: RootState) =>
  state.availability.listFetchState;
export const getAvailabilityFetchedAt = (state: RootState) =>
  state.availability.listFetchedAt;

// Cart
export const getCartAvailability = (state: RootState) =>
  state.cart.availability;
export const getCartAppointmentProduct = (state: RootState) =>
  state.cart.appointmentProduct;
export const getCartParticipants = (state: RootState) =>
  state.cart.participants;
export const getCartPaymentMethod = (state: RootState) =>
  state.cart.paymentMethod;
export const getCartPriceDetails = (state: RootState) =>
  state.cart.priceDetails;
export const getCartStatus = (state: RootState) => state.cart.status;
export const getCartUTMData = (state: RootState) => state.cart.utmData;
export const getCartError = (state: RootState) => state.cart.error;
export const getCartCheckoutMethod = (state: RootState) =>
  state.cart.checkoutMethod;
export const getCartSource = (state: RootState) => state.cart.source;
export const getCartNumParticipants = (state: RootState) =>
  state.cart.numParticipants;
export const getCart = (state: RootState) => state.cart.cart;

// Facility List
export const getFacilityDetail = (state: RootState) => state.facility.detail;
export const getFacilitiesSchedulable = (state: RootState) =>
  state.facility.schedulable;
export const getFacilitiesSchedulableFetchState = (state: RootState) =>
  state.facility.schedulableFetchState;

// Favourites
export const getFavouritesList = (state: RootState) => state.favourites.list;
export const getFavouritesFetchState = (state: RootState) =>
  state.favourites.fetchState;
export const getFavouritesLoaded = (state: RootState) =>
  state.favourites.isLoaded;

// Schedule
export const getScheduleRenderDate = (state: RootState) =>
  state.schedule.renderDate;
export const getScheduleFilterDate = (state: RootState) =>
  state.schedule.filterDate;
export const getScheduleListMode = (state: RootState) =>
  state.schedule.listMode;
export const getScheduleAppointmentProduct = (state: RootState) =>
  state.schedule.appointmentProduct;
export const getScheduleAppointmentProducts = (state: RootState) =>
  state.schedule.appointmentProducts.data;
export const getAppointmentProductsFetchState = (state: RootState) =>
  state.schedule.appointmentProducts.fetchState;
export const getAppointmentProductsFetchedAt = (state: RootState) =>
  state.schedule.appointmentProducts.fetchedAt;
export const getScheduleChanges = (state: RootState) => state.schedule.changes;
export const getScheduleOpenings = (state: RootState) =>
  state.schedule.openings.data;
export const getScheduleOpeningsFetchState = (state: RootState) =>
  state.schedule.openings.fetchState;
export const getScheduleWeeklySchedule = (state: RootState) =>
  state.schedule.weeklySchedule;
export const getScheduleFacilityWeeklySchedules = (state: RootState) =>
  state.schedule.facilityWeeklySchedules;

// Instructors
export const getInstructorsSchedulable = (state: RootState) =>
  state.instructor.schedulable
    ? state.instructor.schedulable.filter((instructor) =>
        ListedStatuses.includes(instructor.status)
      )
    : undefined;
export const getInstructorsAllSchedulable = (state: RootState) =>
  state.instructor.schedulable;
export const getInstructorDetails = (state: RootState) =>
  state.instructor.details;
export const getInstructorOnboarding = (state: RootState) =>
  state.instructor.onboarding;
export const getInstructorFetchState = (state: RootState) =>
  state.instructor.fetchState;
export const getInstructorListFetchState = (state: RootState) =>
  state.instructor.listFetchState;

// Clients
export const getClientList = (state: RootState) => state.clients.list;
export const getClientDetail = (state: RootState) => state.clients.detail;
export const getClientListFetchState = (state: RootState) =>
  state.clients.listFetchState;

// Proposals
export const getProposals = (state: RootState) => state.proposals.list;
export const getProposalsFetchedAt = (state: RootState) =>
  state.proposals.listFetchedAt;
export const getProposalsFetchState = (state: RootState) =>
  state.proposals.listFetchState;
export const getProposalDetail = (state: RootState) => state.proposals.detail;
export const getProposalCreateList = (state: RootState) =>
  state.proposals.create;
export const getProposalClient = (state: RootState) => state.proposals.client;
export const getProposalUpdate = (state: RootState) => state.proposals.update;
export const getProposalListFetchState = (state: RootState) =>
  state.proposals.listFetchState;
export const getProposalDetailFetchState = (state: RootState) =>
  state.proposals.detailFetchState;
export const getProposalCreateState = (state: RootState) =>
  state.proposals.createFetchState;

/** Assistance */
export const getAssistanceReport = (state: RootState) =>
  state.assistance.report;
export const getProxySession = (state: RootState) =>
  state.assistance.proxySession;

/** Appointments */
export const getAppointment = (state: RootState) =>
  state.appointments.detail.data;
export const getAppointmentFetchState = (state: RootState) =>
  state.appointments.detail.fetchState;
export const getAppointments = (state: RootState) =>
  state.appointments.list.data;
export const getAppointmentsFetchState = (state: RootState) =>
  state.appointments.list.fetchState;
export const getAppointmentsFetchedAt = (state: RootState) =>
  state.appointments.list.fetchedAt;
export const getClientHasMoreAppointments = (state: RootState) =>
  state.appointments.clientListHasMore;
export const getUpcomingAppointments = (state: RootState) =>
  state.appointments.upcoming;

export const getAppointmentProduct = (id: string) => (state: RootState) => {
  if (!id) {
    return undefined;
  }
  const aptProds = getScheduleAppointmentProducts(state);
  if (!aptProds || aptProds.length === 0) {
    return undefined;
  }
  return aptProds.find((aptProd) => aptProd.id === id);
};

// Messaging
export const getMessagingInit = (state: RootState) => state.messaging.init;
export const getMessagingConnectionState = (state: RootState) =>
  state.messaging.connectionState;
export const getMessagingUser = (state: RootState) => state.messaging.user;
export const getConversations = (state: RootState) =>
  state.messaging.conversations;
export const getParticipants = (state: RootState) =>
  state.messaging.participants;
export const getMessages = (state: RootState) => state.messaging.messages;
export const getActiveConversationSid = (state: RootState) =>
  state.messaging.activeConversationSid;
export const getActiveConvoHasMoreMessages = (state: RootState) =>
  state.messaging.activeConversationSid &&
  state.messaging.messages[state.messaging.activeConversationSid]?.length &&
  state.messaging.messages[state.messaging.activeConversationSid][0].index !==
    0;
// export const getActiveConversationInputValue = (state: RootState) =>
//   state.messaging.conversationInputValues[
//     state.messaging.activeConversationSid
//   ] ?? '';

// Reviews
/**
 * Returns instructor reviews submitted by this user
 */
export const getReviewsList = (state: RootState) => state.reviews.list;

// Search
export const getActivity = (state: RootState) => state.search.activity;
export const getSearchCities = (state: RootState) => state.search.cities;
export const getSearchSegments = (state: RootState) => state.search.segments;
export const getSearchCity = (state: RootState) => state.search.city;
export const getSearchFacilities = (state: RootState) =>
  state.search.facilities;
export const getSearchFacility = (state: RootState) => state.search.facility;
export const getSearchFacilityGoogleReviews = (state: RootState) =>
  state.search.facilityGoogleReviews.reviews;
export const getSearchFacilityGoogleReviewsCount = (state: RootState) =>
  state.search.facilityGoogleReviews.count;
export const getSearchInstructors = (state: RootState) =>
  state.search.instructors;
export const getSearchInstructor = (state: RootState) =>
  state.search.instructor;
export const getSearchAvailability = (state: RootState) =>
  state.search.apptProdAvailability;
export const getSearchApptProdAvailability =
  (appointmentProductId: string) => (state: RootState) =>
    state.search.apptProdAvailability[appointmentProductId];
export const getSearchPreferences = (state: RootState) =>
  state.search.searchPreferences;
export const hasAppliedSearchPreferences = (state: RootState) => {
  const prefs = state.search.searchPreferences;
  return (
    prefs.activity !== undefined ||
    prefs.segment !== undefined ||
    prefs.times.length > 0
  );
};
export const getSearchApptProdAvailabilityFetchState =
  (appointmentProductId: string) => (state: RootState) =>
    state.search.apptProdAvailabilityFetchStates[appointmentProductId] ??
    FETCH_STATE.PRISTINE;
export const getSearchAppointmentProducts = (state: RootState) =>
  state.search.appointmentProducts.data;
export const getSearchAppointmentProductsFetchState = (state: RootState) =>
  state.search.appointmentProducts.fetchState;
export const getSearchAppointmentProduct =
  (appointmentProductId: string) => (state: RootState) =>
    state.search.appointmentProducts.data.find(
      (elt) => elt.id === appointmentProductId
    );
export const getSearchInstructorSlug = (state: RootState) =>
  state.search.slugs.instructor;
export const getSearchFacilitySlug = (state: RootState) =>
  state.search.slugs.facility;
export const getSearchInstructorReviews = (state: RootState) =>
  state.search.instructorReviews;
export const getSearchFacilitiesFetchState = (state: RootState) =>
  state.search.fetchStates.facilities;
export const getSearchInstructorsFetchState = (state: RootState) =>
  state.search.fetchStates.instructors;
export const getSearchInstructorFetchState = (state: RootState) =>
  state.search.fetchStates.instructor;
export const getSearchApptProdIds = (state: RootState) =>
  state.search.bookMoreApptProdIds;
export const getSearchSorting = (state: RootState) =>
  state.search.bookMoreSorting;
