export const IS_SERVER = !(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);
export const env = IS_SERVER ? process.env : window.env;
export const APP_RELEASE = env.REACT_APP_RELEASE;
export const DEBUG = env.REACT_APP_ENV !== 'production';
export const RUN_SENTRY_DEV = false;
// export const RUN_SENTRY_DEV = true; // TESTING ONLY
export const APP_ROOT_URL = env.REACT_APP_APP_ROOT_URL;
export const API_ROOT_URL = env.REACT_APP_API_ROOT_URL;
export const API_ROOT = API_ROOT_URL + '/v1/';
export const LOCAL_STORAGE_KEY = 'PROPEL_LOCAL_STATE';
export const SESSION_STORAGE_KEY = '__prpl__';
export const COOKIE_MAX_AGE = 120;
export const COLOR_PRIMARY_400 = '#00aeff';
export const COLOR_GREY_600 = '#3D454C';
export const COLOR_GREY_500 = '#5A6772';
export const COLOR_GREY_400 = '#818C9C';
/**
 * FEATURE FLAGS
 */

/**
 * KEYS
 */
export const CLIENT_BEAMER_KEY = 'sNAyGnYsnull';
export const INSTRUCTOR_BEAMER_KEY = 'GIWUJsSOnull';
export const GOOGLE_MAPS_API_KEY = env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const SENTRY_CLIENT_KEY = env.REACT_APP_SENTRY_CLIENT_KEY;
export const STRIPE_PUBLIC_KEY = env.REACT_APP_STRIPE_PUBLIC_KEY;
export const SEGMENT_JS_KEY = env.REACT_APP_SEGMENT_JS_KEY;
export const POSTHOG_KEY = env.REACT_APP_POSTHOG_KEY;
export const TOKEN_PREFIX = 'Bearer';
/**
 * CONFIG VARS
 */
export const PAYOUT_CONTROL_DATE = '2019-05-31T12:00:00Z';
export const ONBOARDING_V2_DATE = '2022-12-31T23:00:00Z';
export const CAL_LIST_ITEM_NAME_PREFIX = 'sched-li';
// TIMINGS //
export const STORE_THROTTLE_MS = 1000; // ms debounce localStorage redux sync
export const ACCESS_TOKEN_LIFETIME_MINS = 60;
export const ACCESS_TOKEN_REFRESH_THRESHOLD_MINS = 10;
export const NETWORK_RETRIES_BASE_DELAY_MS = 1000;
export const NETWORK_RETRIES_INCREASE_DELAY_MS = 500;
export const MAX_NETWORK_RETRIES = 10;
export const NETWORK_RETRIES_BEFORE_ALERT_USER = 3;
export const LEAD_MAGNET_POPUP_DELAY_SEC = 8;
export const PAYMENT_AUTH_DAYS = 3.5;
export const PAYMENT_SETTLEMENT_HOURS = 24;
export const CANCEL_POLICY_HRS = 48;
/**
 * Business Logic Variables
 */
/**
 * Maximum percentage for an instructor's late cancellation rate
 * below which Propel will reimburse the host's admission fee
 */
export const INSTRUCTOR_LATE_CANCELLATION_RATE_MAX = 0.035;
export const INSTRUCTOR_LATE_CANCELLATION_RATE_AVG = 0.02;
export const INSTRUCTOR_LATE_CANCELLATION_RATE_ELITE = 0.01;
/**
 * Maximum percentage for an instructor's overall cancellation rate,
 * above which they are not shown in facility lists
 */
export const INSTRUCTOR_OVERALL_DELIVERY_RATE_MIN = 0.75;
export const INSTRUCTOR_OVERALL_DELIVERY_RATE_AVG = 0.95;
export const INSTRUCTOR_OVERALL_DELIVERY_RATE_ELITE = 0.98;
/**
 * When Summer prices start
 */
export const SUMMER_START_DATE = '2020-07-01';
/**
 * The date when "Next Summer" changes from this year to next year
 */
export const NEXT_SUMMER_SWITCHOVER_DATE = '2020-06-01';
export const MAX_PRICE = 200;
export const MIN_PRICE = 45;
export const MAX_CURRENT_PRICES = 2; // maximum number of prices from current and into the future allowed
export const INSTRUCTOR_RATE_INCR = 5; // increment of instructor prices
export const AVG_SPACE_PER_INSTRUCTOR = 20; // number of available spots an instructor submits on average
export const ROTATE_BY = -90;
export const NUM_SEARCH_DAYS = 30;
/**
 * Number of prior days that cancellation rate takes into account
 */
export const RECENT_CANCELLATION_NUM_DAYS = 60;
export const APPOINTMENT_LENGTH = 60;
export const DAYS_AFTER_APPOINTMENT_REPORT = 2;
export const MIN_HOURS_UNTIL_PROPOSAL_START = 48;
export const MAX_DAYS_UNTIL_PROPOSAL_EXPIRES = 7;
export const MAX_LATE_BOOKING_HOUR = 21; // 9pm
export const MIN_SWIMMER_AGE = 2; // Minimum age of swimmers allowed to be added in Propel
export const MIN_INSTRUCTOR_AGE = 18; // Minimum age of instructors allowed to be on Propel
export const MIN_CANCEL_NOTE_LENGTH = 30; // minimum character count in cancellation reason
export const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LENGTH = 64;
export const MAX_EMAIL_LENGTH = 150;
export const MIN_USERNAME_LENGTH = 3;
export const ANIMATE_MS = 250; // ms that animations typically take place for - eg: closing modal window
export const DAYS_PER_AVAILABILITY_PAGE = 3;
// Image Upload config
export const MAX_IMAGE_UPLOAD_DIMENSION = 2500;
export const MAX_IMAGE_UPLOAD_FILESIZE_BYTES = 10485760; // 10MB upload limit
export const REVIEWS_PER_PAGE = 3; // number of reviews to display on an instructor's detail page at a time
export const YOUTUBE_VIDEO = `https://www.youtube.com/embed/9mC6x5doGqA?rel=0&showinfo=0${
  DEBUG ? '' : '&autoplay=1'
}`;
export const MESSAGING_PAGE_SIZE = 30;
export const DAY_HOURS = 24;

export type MaxWidth =
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl'
  | '4xl'
  | '5xl'
  | '6xl'
  | 'full';

export const SentryTag = {
  Environment: 'environment',
  DeviceId: 'deviceId',
  SessionId: 'sessionId',
};

export enum Action {
  ASSISTANCE = 'assistance',
  DETAIL = 'detail',
  PROBLEM = 'report',
  CANCEL = 'cancel',
  BOOK = 'book',
  REVIEW = 'review',
  APPROVE = 'approve',
  DIAL_THROUGH = 'dial-through',
}

/**
 * Querystring parameters
 */
export const QueryParams = {
  Action: 'action',
  ActivateKey: 'key',
  AppointmentId: 'appointment',
  AppointmentProductId: 'aptProdId',
  Audience: 'audience',
  Availability: 'availability',
  Campaign: 'utm_campaign',
  Category: 'category',
  GiftCardImg: 'giftCardImg',
  GiftCardPin: 'giftCardPin',
  ClientId: 'cid',
  Date: 'date',
  End: 'end',
  Exp: 'exp',
  Facility: 'facility',
  FacilityId: 'fid',
  Filter: 'filter',
  Ids: 'ids',
  Instructor: 'instructor',
  InstructorId: 'iid',
  Invoice: 'invoice',
  Medium: 'utm_medium',
  Mode: 'mode',
  Month: 'month',
  NewCredit: 'newCredit',
  Next: 'next',
  Source: 'utm_source',
  SuppressAssistance: 'suppressAssistance',
  Positive: 'positive',
  Promo: 'promo',
  ProposalId: 'proposal',
  Recipient: 'recipient',
  Search: 'search',
  ShowVerificationResult: 'showVerificationResult',
  Sid: 'sid',
  Start: 'start',
  Step: 'step',
  Time: 'time',
};

/**
 * fetch call methods
 */
export enum METHOD {
  PUT = 'PUT',
  POST = 'POST',
  DELETE = 'DELETE',
  GET = 'GET',
  PATCH = 'PATCH',
}

export enum EventItemMode {
  READ,
  CREATE,
  MODIFY,
  DELETE,
}

export enum SubmitState {
  UNSUBMITTED,
  IN_TRANSIT,
  SUBMITTED,
  ERROR,
}

export const FETCH_STATE = {
  PRISTINE: 'PRISTINE' as FETCH_STATE,
  IDLE: 'IDLE' as FETCH_STATE,
  FULFILLED: 'FULFILLED' as FETCH_STATE,
  FAILED: 'FAILED' as FETCH_STATE,
  GET: 'GET' as FETCH_STATE,
  POST: 'POST' as FETCH_STATE,
  PUT: 'PUT' as FETCH_STATE,
};

export type FETCH_STATE =
  | 'PRISTINE'
  | 'IDLE'
  | 'FULFILLED'
  | 'PENDING'
  | 'FAILED'
  | 'GET'
  | 'POST'
  | 'PUT';

export const RESOLVED_FETCH_STATES = [
  FETCH_STATE.FULFILLED,
  FETCH_STATE.FAILED,
];

export enum UserType {
  Unset = '',
  Anonymous = 'anonymous',
  Instructor = 'instructor',
  Client = 'client',
  Host = 'host',
  Admin = 'admin',
}

export const MAX_LENGTHS = {
  ADDRESS_LINE1: 60,
  ADDRESS_LINE2: 60,
  ADDRESS_CITY: 50,
  ADDRESS_PROVINCE: 35,
  ADDRESS_COUNTRY: 100,
  ADDRESS_POSTAL_CODE: 20,
};

export const FIELDS = {
  USER_TYPE: 'userType',
  IS_TOS_SIGNED: 'isTosSigned',
  ADDRESS: 'address',
  INSTRUCTOR_CERTIFICATE_URL: 'certificateUrl',
  DISPLAY_NAME: 'displayName',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  PHONE_NUMBER: 'phoneNumber',
  LINE1: 'line1',
  LINE2: 'line2',
  CITY: 'city',
  REGION: 'region',
  COUNTRY: 'country',
  POSTAL_CODE: 'postalCode',
  BIRTHDATE: 'birthdate',
  AVATAR: 'avatar',
  MEDIA: 'media',
  DESCRIPTION: 'description',
  FACILITY: {
    HOURS_OF_OPERATION_DATA: 'hoursOfOperationData',
    NUM_CONCURRENT_APPOINTMENTS: 'numConcurrentAppointments',
    CORPORATION_NAME: 'corporationName',
    DAILY_EMAIL_TIME: 'dailyEmailTime',
    DAILY_EMAIL_RECIPIENTS: 'dailyEmailRecipients',
    INSTRUCTOR_ACCESS_INFO: 'instructorAccessInfo',
    CLIENT_ACCESS_INFO: 'clientAccessInfo',
    POOL_DIMENSIONS: 'poolDimensions',
    MAX_LENGTH: 'maxLength',
    MIN_DEPTH: 'minDepth',
    MAX_DEPTH: 'maxDepth',
    TEMPERATURE: 'temperature',
  },
};

// Two DaysOfWeek variables for a Sunday and Monday start to week
// Py vs JS endings bcs in Python Dates rrule parameters, byweekday=0 is Monday
// whereas in JS moment().day() === 0 is Sunday
export enum DAY {
  SU,
  MO,
  TU,
  WE,
  TH,
  FR,
  SA,
}
export enum Weekday {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}
export enum WeekdayLower {
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday',
}

export const DAYS_OF_WEEK_PY: Weekday[] = [
  Weekday.Monday,
  Weekday.Tuesday,
  Weekday.Wednesday,
  Weekday.Thursday,
  Weekday.Friday,
  Weekday.Saturday,
  Weekday.Sunday,
];

export const DAYS_OF_WEEK_JS: Weekday[] = [
  Weekday.Sunday,
  Weekday.Monday,
  Weekday.Tuesday,
  Weekday.Wednesday,
  Weekday.Thursday,
  Weekday.Friday,
  Weekday.Saturday,
];

export const MONTH_NAMES: string[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const DATE_FMT = {
  /** Tuesday, January 25, 2021 10:00am */
  DISPLAY_DT: 'dddd, MMMM D, YYYY h:mma',
  /** 4pm */
  HOUR_A: 'ha',
  /** 4:30 */
  TIME: 'h:mm',
  /** 4:30pm */
  TIME_A: 'h:mma',
  /** 17:00 */
  TIME_24: 'HH:mm',
  /** 17:05:00 */
  TIME_FIELD: 'HH:mm:ss',
  /** 25 */
  D: 'D',
  /** 25th */
  D_ORDINAL: 'Do',
  /** Wed */
  DOW_SHORT: 'ddd',
  /** Wednesday */
  DOW_FULL: 'dddd',
  /** Wed 4:30pm */
  DOW_TIME_A: 'ddd h:mma',
  /** Thurs, Jan 25 */
  DOW_SHORT_MON_D: 'ddd, MMM D',
  /** Thursday, Jan 25 */
  DOW_MON_D: 'dddd, MMM D',
  /** Thursday, January 25 */
  DOW_MONTH_D: 'dddd, MMMM D',
  /** Thursday, Jan 25, 2020 */
  DOW_MON_D_YEAR: 'dddd, MMM D, YYYY',
  /** Thursday, January 25, 2020 */
  DOW_MONTH_D_YEAR: 'dddd, MMMM D, YYYY',
  /** Thursday, January 25, 5:30pm */
  DOW_MONTH_D_TIME_A: 'dddd, MMMM D, h:mma',
  /** 5:30pm, July 20, 2022 */
  TIME_A_MONTH_D_YEAR: 'h:mma, MMMM D, YYYY',
  /** 4/25 */
  M_D: 'M/D',
  /** Jan 25, 1984 */
  MON_D_YEAR: 'MMM D, YYYY',
  /** Jan 2 */
  MON_D: 'MMM D',
  /** January */
  MONTH: 'MMMM',
  /** January 2 */
  MONTH_D: 'MMMM D',
  /** January 2nd */
  MONTH_D_ORDINAL: 'MMMM Do',
  /** Jan 2nd */
  MON_D_ORDINAL: 'MMM Do',
  /** January 2018 */
  MONTH_YEAR: 'MMMM Y',
  /** January 2, 2020 */
  MONTH_D_YEAR: 'MMMM D, YYYY',
  /** January 25, 5:30pm */
  MONTH_D_TIME_A: 'MMMM D, h:mma',
  /** January 25, 2020, 5:30pm */
  MONTH_D_YEAR_TIME_A: 'MMMM D, YYYY, h:mma',
  /** Jan 25, 5:30pm */
  MON_D_TIME_A: 'MMM D, h:mma',
  /** Jan 25, 2018, 5:30pm */
  MON_D_YEAR_TIME_A: 'MMM D, YYYY, h:mma',
  /** 2019-5-9 */
  SERVER_DATE: 'YYYY-MM-DD',
  /** 2012-02 */
  YEAR_MO: 'YYYY-MM',
  /** 2012-02-24 */
  YEAR_MO_DD: 'YYYY-MM-DD',
  /** 2012-02-24 */
  DATE_KEY: 'YYYY-MM-DD',
  /** 2018-03-01T02:30:00Z */
  DATETIME_FIELD: 'YYYY-MM-DDTHH:mm:ssZ',
  /** 2018-03-01 17:05:00 -07:00*/
  DATETIME_TZ: 'YYYY-MM-DD HH:mm:ss Z',
  /** 2018-03-01 */
  DATE_FIELD: 'YYYY-MM-DD',
  /** 12/20/1987 */
  DATE_INPUT: 'M/D/YYYY',
  /** 1677700459 */
  UNIX_SEC: 'X',
  /** 1677700459000 */
  UNIX_MS: 'x',
};

export const HIATUS_REASON_OPTIONS = [
  {
    label: 'Attending school',
    value: 'School',
  },
  {
    label: 'Working another job',
    value: 'OtherJob',
  },
  {
    label: 'Moved away temporarily',
    value: 'MovedTemporarily',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const HIATUS_LENGTH_OPTIONS = [
  {
    label: 'A week',
    value: 7,
  },
  {
    label: 'A month',
    value: 30,
  },
  {
    label: 'Three months',
    value: 90,
  },
  {
    label: 'Six months',
    value: 180,
  },
  {
    label: 'A year',
    value: 365,
  },
  {
    label: 'Indefinitely',
    value: null,
  },
];

export const DONE_WITH_PROPEL_REASON_OPTIONS = [
  {
    label: 'Not enough bookings/earnings',
    value: 'Money',
  },
  {
    label: 'Working another job',
    value: 'OtherJob',
  },
  {
    label: 'Moved or pools located too far away',
    value: 'NoPools',
  },
  {
    label: 'No longer teaching swimming',
    value: 'NotTeaching',
  },
  {
    label: "Don't want to recertify qualifications",
    value: 'NotRecertifying',
  },
  {
    label: 'Difficulty using the Propel platform',
    value: 'Difficult',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const NOTIFICATION_TYPES = [
  {
    label: 'Daily agenda',
    description: 'Appointment list for the next day',
    value: 1,
  },
  {
    label: 'Weekly summary',
    description: 'Summary of feedback, schedule, and earnings',
    value: 2,
  },
  {
    label: 'Monthly financials',
    description: 'Earnings summary',
    value: 3,
  },
  {
    label: 'Maintenance notices',
    description: 'Reports when servicing may be required',
    value: 4,
  },
];

export const RESPONSIVE_FULL_WIDTH_SLIDER_MULTI = {
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const RESPONSIVE_FULL_WIDTH_SLIDER_SINGLE = {
  centerMode: false,
  infinite: true,
  slidesToShow: 1,
  arrows: true,
};

export const TIME_OPTIONS: Array<{ label: string; value: string }> = [
  {
    label: '6am',
    value: '06:00:00',
  },
  {
    label: '7am',
    value: '07:00:00',
  },
  {
    label: '8am',
    value: '08:00:00',
  },
  {
    label: '9am',
    value: '09:00:00',
  },
  {
    label: '10am',
    value: '10:00:00',
  },
  {
    label: '11am',
    value: '11:00:00',
  },
  {
    label: '12pm',
    value: '12:00:00',
  },
  {
    label: '1pm',
    value: '13:00:00',
  },
  {
    label: '2pm',
    value: '14:00:00',
  },
  {
    label: '3pm',
    value: '15:00:00',
  },
  {
    label: '4pm',
    value: '16:00:00',
  },
  {
    label: '5pm',
    value: '17:00:00',
  },
  {
    label: '6pm',
    value: '18:00:00',
  },
  {
    label: '7pm',
    value: '19:00:00',
  },
  {
    label: '8pm',
    value: '20:00:00',
  },
  {
    label: '9pm',
    value: '21:00:00',
  },
  {
    label: '10pm',
    value: '22:00:00',
  },
];

export const SORT_OPTIONS: { label: string; value: string }[] = [
  {
    label: 'Most relevant',
    value: '-lastBookedAt,-isFavourite,distance,-availabilityCount',
  },
  { label: 'Most availability', value: '-availabilityCount,distance' },
  { label: 'Most testimonials', value: '-testimonialCount,-availabilityCount' },
  { label: 'Lowest price', value: 'price,-availabilityCount' },
];
