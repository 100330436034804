import {
  InstructorCancellationKPIs,
  InstructorEarningsHistory,
} from 'api/Serializers/Analytics';
import { AppointmentListSerializer } from 'api/Serializers/Appointments';
import Dashboard from 'components/account/dashboard';
import Button from 'components/button';
import CardActions from 'components/card-actions';
import Link from 'components/link';
import Loading from 'components/loading';
import Modal from 'components/modal';
import {
  DATE_FMT,
  FETCH_STATE,
  INSTRUCTOR_LATE_CANCELLATION_RATE_AVG,
  INSTRUCTOR_LATE_CANCELLATION_RATE_ELITE,
  INSTRUCTOR_LATE_CANCELLATION_RATE_MAX,
  INSTRUCTOR_OVERALL_DELIVERY_RATE_AVG,
  INSTRUCTOR_OVERALL_DELIVERY_RATE_ELITE,
  INSTRUCTOR_OVERALL_DELIVERY_RATE_MIN,
  QueryParams,
} from 'config';
import { ScheduleObject } from 'features/schedule/as-instructor/bookings';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { HelpIcon } from 'icons';
import moment from 'moment-timezone';
import { INSTRUCTOR_ROUTES } from 'pages/account/instructor/utils';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getAccountCancellationMetrics,
  getAccountEarnings,
  getAccountEarningsFetchState,
  getEarlyAccessWindows,
  getInstructorOnboarding,
  getUpcomingAppointments,
} from 'state/selectors';
import { fetchEarlyAccessWindows } from 'state/slice/account';
import { fetchNextAppointments } from 'state/slice/appointments';
import { LocalStore } from 'state/storage';
import { isTaxSeason } from 'utils/date';
import { SHARED_ROUTES, SHOW_HELP } from 'utils/routing';

const Overview = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [futureDates, setFutureDates] = useState<
    [string, AppointmentListSerializer[]][]
  >([]);
  const dispatch = useAppDispatch();
  const appointments = useSelector(getUpcomingAppointments);
  const earlyAccessWindows = useSelector(getEarlyAccessWindows);
  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      dispatch(fetchNextAppointments()),
      dispatch(fetchEarlyAccessWindows()),
    ]).then((responses) => {
      setIsLoading(false);
    });
  }, []);
  useEffect(() => {
    const today = moment().format(DATE_FMT.DATE_KEY);
    setFutureDates(
      Object.entries(
        appointments
          .filter((apt) => !apt.cancelled && apt.date >= today)
          .groupBy((apt) => apt.date)
      )
    );
  }, [appointments]);
  if (isLoading) {
    return <Loading message="Getting updates..." />;
  }
  return (
    <div className="space-y-4">
      {earlyAccessWindows.length > 0 && (
        <div className="card">
          <h2>Schedule early access</h2>
          {earlyAccessWindows.map((earlyAccessWindow) => {
            return (
              <div key={earlyAccessWindow.id}>
                <p>
                  {earlyAccessWindow.facility.displayName} has a new schedule,
                  and you have early access!
                </p>
                <p>
                  Starting{' '}
                  <span className="font-semibold">
                    {moment(earlyAccessWindow.startDate).format(
                      DATE_FMT.DOW_MONTH_D
                    )}
                  </span>{' '}
                  until end of day{' '}
                  <span className="font-semibold">
                    {moment(earlyAccessWindow.endDate).format(DATE_FMT.MONTH_D)}
                  </span>
                  , you have exclusive access to send proposals to your clients.
                </p>
                <p>
                  The schedule opens for general availability on{' '}
                  {moment(earlyAccessWindow.rollout.publicAccessDate).format(
                    DATE_FMT.DOW_MONTH_D_YEAR
                  )}
                  .
                </p>
              </div>
            );
          })}
          <CardActions>
            <Button
              variant="outlined"
              color="primary"
              to={INSTRUCTOR_ROUTES.SCHEDULE.ROOT}
            >
              Open Calendar
            </Button>
          </CardActions>
        </div>
      )}
      <div className="card">
        <h2>Next-up</h2>
        {futureDates.length === 0 ? (
          <div className="p-4 my-4 text-gray-500 bg-gray-200 rounded-lg">
            No upcoming bookings
          </div>
        ) : (
          <div className="space-y-4">
            {futureDates.slice(0, 3).map(([date, appointments]) => {
              const isToday = moment().isSame(date, 'date');
              const dDays = Math.abs(moment().diff(date, 'days')) + 1;
              const preHeader = isToday
                ? 'Today'
                : dDays === 1
                ? 'Tomorrow'
                : `In ${dDays} ${'day'.pluralize(dDays)}`;
              return (
                <div key={date}>
                  <h6>{preHeader}</h6>
                  <h5>
                    {appointments[0].facility.displayName},{' '}
                    {moment(date).format(DATE_FMT.DOW_MON_D)}
                  </h5>
                  <div className="-mx-6 divide-y divide-gray-300">
                    {appointments.slice(0, 3).map((appointment) => (
                      <Link
                        key={appointment.id}
                        className="block"
                        to={`${SHARED_ROUTES.SCHEDULE.ROOT}?${QueryParams.AppointmentId}=${appointment.id}`}
                      >
                        <ScheduleObject
                          object={{ ...appointment, type: 'APPOINTMENT' }}
                          isClickable={true}
                        />
                      </Link>
                    ))}
                    {appointments.length > 3 && (
                      <div className="px-4 py-2 m-2 text-base italic">{`And ${
                        appointments.length - 3
                      } more booked this day...`}</div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <CardActions>
          <Button
            variant="outlined"
            color="primary"
            to={INSTRUCTOR_ROUTES.SCHEDULE.ROOT}
          >
            Open Calendar
          </Button>
        </CardActions>
      </div>
    </div>
  );
};

const CardHelpButton = ({ onClick }) => (
  <span>
    <button
      onClick={onClick}
      className="text-gray-600 transition-colors duration-150 hover:text-blue-500"
    >
      <HelpIcon width={24} />
    </button>
  </span>
);

const RateKPI = ({
  rate,
  rateType = 'percentage',
  badgeText = undefined,
  badgeColor = undefined,
  title,
  description,
  onClickHelp = undefined,
}: {
  rate: string;
  rateType?: 'percentage' | 'dollars' | 'hourlyDollars';
  badgeText?: string;
  badgeColor?: string;
  title: string;
  description: string;
  onClickHelp?(): void;
}) => {
  const hideBadge = badgeText === undefined || badgeColor === undefined;
  return (
    <div className="card h-full">
      <div className="mb-4">
        <div className="flex justify-between gap-1">
          <span className="text-5xl font-extrabold leading-none">
            {rateType === 'percentage' ? `${rate}%` : `$${rate}`}
            {rateType === 'hourlyDollars' && (
              <span className="font-bold text-md">/hr</span>
            )}
          </span>
          {onClickHelp !== undefined && (
            <CardHelpButton onClick={onClickHelp} />
          )}
        </div>
        <div>
          <span
            className={`badge ${badgeColor}${hideBadge ? 'invisible' : ''}`}
          >
            {badgeText}
          </span>
        </div>
      </div>
      <div>
        <span className="mb-1 text-gray-700 font-bold block">{title}</span>
        <div className="line-clamp-2 text-sm text-gray-600">{description}</div>
      </div>
    </div>
  );
};

const LessonDeliveryRateKPI = ({
  cancellationMetrics,
}: // handleClickHelp,
{
  cancellationMetrics: InstructorCancellationKPIs;
  // handleClickHelp(): void;
}) => {
  const rate = Number(cancellationMetrics?.deliveryRate);
  let badgeColor = 'green';
  let badgeText = 'Top performer';
  const description =
    'Stay above 75% to have your profile shown to new clients.';
  if (cancellationMetrics.creditedLessons === 0) {
    badgeColor = undefined;
    badgeText = undefined;
  } else if (rate < INSTRUCTOR_OVERALL_DELIVERY_RATE_MIN) {
    badgeColor = 'red';
    badgeText = 'Very low';
  } else if (rate < INSTRUCTOR_OVERALL_DELIVERY_RATE_AVG) {
    badgeColor = 'yellow';
    badgeText = 'Below average';
  } else if (rate < INSTRUCTOR_OVERALL_DELIVERY_RATE_ELITE) {
    badgeColor = 'blue';
    badgeText = 'Very good';
  }

  return (
    <RateKPI
      rate={Math.round(cancellationMetrics.deliveryRate * 100).toString()}
      badgeColor={badgeColor}
      badgeText={badgeText}
      description={description}
      // onClickHelp={handleClickHelp}
      title="Lesson delivery rate"
    />
  );
};

const PayProtectionsKPI = ({
  cancellationMetrics,
  onClickHelp,
}: {
  cancellationMetrics: InstructorCancellationKPIs;
  onClickHelp(): void;
}) => {
  const value = Math.round(cancellationMetrics.netPayProtections);
  let valueStr: string;
  if (value < 1000) {
    valueStr = value.toString();
  } else {
    valueStr = (Math.round(value / 100) / 10).toString() + 'k';
  }
  return (
    <RateKPI
      rate={valueStr}
      rateType="dollars"
      // badgeColor={badgeColor}
      // badgeText={badgeText}
      description="Earnings, savings, and costs from cancellation protections."
      onClickHelp={onClickHelp}
      title="Net cancellation benefits"
    />
  );
};

const LateCancellationRateKPI = ({
  cancellationMetrics,
}: // handleClickHelp,
{
  cancellationMetrics: InstructorCancellationKPIs;
  // handleClickHelp(): void;
}) => {
  const rate = Number(cancellationMetrics.lateCancellationRate);
  let badgeColor = 'green';
  let badgeText = 'Top performer';
  const description =
    "Stay below 3.5% and we'll cover admission when you're absent.";
  if (cancellationMetrics.creditedLessons === 0) {
    badgeColor = undefined;
    badgeText = undefined;
  } else if (rate > INSTRUCTOR_LATE_CANCELLATION_RATE_MAX) {
    badgeColor = 'red';
    badgeText = 'Very high';
  } else if (rate > INSTRUCTOR_LATE_CANCELLATION_RATE_AVG) {
    badgeColor = 'yellow';
    badgeText = 'Above average';
  } else if (rate > INSTRUCTOR_LATE_CANCELLATION_RATE_ELITE) {
    badgeColor = 'blue';
    badgeText = 'Very good';
  }

  return (
    <RateKPI
      rate={(cancellationMetrics.lateCancellationRate * 100).toFixed(1)}
      badgeColor={badgeColor}
      badgeText={badgeText}
      description={description}
      // onClickHelp={handleClickHelp}
      title="Late cancellation rate"
    />
  );
};

const EarningsKPI = ({
  earningsData,
}: {
  earningsData: InstructorEarningsHistory;
}) => {
  return (
    <RateKPI
      rate={Math.round(earningsData.effectiveRate).toString()}
      rateType="hourlyDollars"
      title="Hourly earnings"
      description="The total paid out to you divided by total lessons taught"
    />
  );
};

const KPIs = () => {
  const earnings = useSelector(getAccountEarnings);
  const earningsFetchState = useSelector(getAccountEarningsFetchState);
  const [showProtectionsModal, setShowProtectionsModal] =
    useState<boolean>(false);
  const cancellationMetrics = useSelector(getAccountCancellationMetrics);

  if (cancellationMetrics === undefined) {
    return (
      <div className="w-full flex justify-center">
        <Loading position="inline-contained" />
      </div>
    );
  }

  return (
    <>
      <div className="grid gap-2 grid-cols-2 md:grid-cols-4">
        <LateCancellationRateKPI cancellationMetrics={cancellationMetrics} />
        <LessonDeliveryRateKPI cancellationMetrics={cancellationMetrics} />
        {(!earnings || earningsFetchState !== FETCH_STATE.GET) && (
          <EarningsKPI earningsData={earnings} />
        )}
        {cancellationMetrics.netPayProtections >= 0 && (
          <PayProtectionsKPI
            cancellationMetrics={cancellationMetrics}
            onClickHelp={() => setShowProtectionsModal(true)}
          />
        )}
      </div>
      <Modal
        maxWidth="xs"
        open={showProtectionsModal}
        onClose={() => setShowProtectionsModal(false)}
        name="Net cancellation benefits"
        title="Net cancellation benefits"
      >
        <div className="flex flex-col gap-4">
          <div className="p-4 space-y-2 rounded-lg bg-background">
            <ul>
              <li className="flex justify-between ">
                <span className="font-semibold">Earnings</span>
                <span>
                  {cancellationMetrics.grossPayProtections.toCurrency()}
                </span>
              </li>
              <p>When clients or hosts cancel late</p>
              <li className="flex justify-between ">
                <span className="font-semibold">Savings</span>
                <span>
                  {cancellationMetrics.admissionFeeReimbursements.toCurrency()}
                </span>
              </li>
              <p>When we cover admission for you</p>
              <li className="flex justify-between ">
                <span className="font-semibold">Costs</span>
                <span>
                  -{cancellationMetrics.admissionFeeDeductions.toCurrency()}
                </span>
              </li>
              <p>When you cover admission yourself</p>
              <hr />
              <li className="flex justify-between font-semibold">
                <span>Net cancellation benefits</span>
                <span>
                  {cancellationMetrics.netPayProtections.toCurrency()}
                </span>
              </li>
            </ul>
          </div>
          <p>
            If you cancel late often you may occasionally be responsible to
            cover admission. However, the overall benefits of cancellation
            protection typically outweigh these minor costs.
          </p>
        </div>
      </Modal>
    </>
  );
};

export const TaxNotice = () => {
  const [inc, setInc] = useState(0);
  const thisYear = moment().year();
  const lastYear = thisYear - 1;
  const taxInfoLastHidden: number =
    LocalStore.getPreference('taxInfoLastHidden');
  const showTaxNotice = isTaxSeason() && taxInfoLastHidden !== thisYear;
  const handleHide = () => {
    setInc(inc + 1); // this causes a rerender
    LocalStore.setPreference('taxInfoLastHidden', thisYear);
  };
  if (!showTaxNotice) {
    return null;
  }
  return (
    <div className="card">
      <h2 className="mb-4">Tax information</h2>
      <p>
        As a self-employed individual, you will likely need to fill out a T2125
        form in addition to your normal tax forms. To make this process easy for
        you, we provide an{' '}
        <Link to={INSTRUCTOR_ROUTES.EARNINGS.DETAIL(lastYear)} underline>
          Annual Summary
        </Link>{' '}
        that breaks down your revenue and expenses for the year.
      </p>
      <CardActions>
        {/* <Button onClick={handleHide} variant="flat">
          Dismiss
        </Button> */}
        <Button
          onClick={SHOW_HELP.TEACHING.FILING_TAXES}
          variant="flat"
          color="primary"
        >
          Learn more
        </Button>
      </CardActions>
    </div>
  );
};

const DashboardContainer = () => {
  const onboarding = useSelector(getInstructorOnboarding);

  return (
    <Dashboard title="Overview" width="4xl">
      <KPIs />
      <TaxNotice />
      <Overview />
    </Dashboard>
  );
};

export default DashboardContainer;
