import {
  Event,
  PaymentIntentStatus,
  TimeFrame,
} from 'api/Serializers/Appointments';
import Avatar from 'components/avatar';
import { ListButton } from 'components/button';
import Callout from 'components/callout';
import Modal from 'components/modal';
import { DATE_FMT, QueryParams, UserType } from 'config';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useQuery from 'hooks/useQuery';
import {
  AccountIcon,
  BookmarkIcon,
  ClockIcon,
  FacilityIcon,
  MapIcon,
} from 'icons';
import moment from 'moment-timezone';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { fetchAppointment } from 'state/slice/appointments';
import { SHARED_ROUTES } from 'utils/routing';
import { SectionProps, SECTION_CLASSES } from '../types';

export const AppointmentCancellationDetails = ({
  appointment,
}: {
  appointment: Event;
}) => {
  const cancellation = appointment.cancellation;
  if (!appointment.cancelled || !cancellation) {
    return null;
  }
  return (
    <div className="my-4 space-y-3">
      <div className="p-4 space-y-2 bg-background rounded-xl">
        <h5>{appointment.activity.appointmentNoun} cancelled</h5>
        <div>{cancellation.description}</div>
        <ul className="pl-6 list-disc">
          {cancellation.results.map((item: string, i: number) => (
            <li key={`sum-${i}`}>{item}</li>
          ))}
        </ul>
      </div>
      {cancellation.notes.map((note, i) => {
        let from;
        if (note.sender == UserType.Admin) {
          from = 'Propel';
        } else if (note.sender == UserType.Instructor) {
          from = appointment.instructor.firstName;
        } else if (note.sender == UserType.Host) {
          from = 'Host';
        } else {
          return null;
        }
        return (
          <div key={`n${i}`}>
            <div className="p-4 space-y-2 bg-background rounded-xl">
              <h5 className="text-base">From {from}:</h5>
              <div className="whitespace-pre-wrap">"{note.text}"</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const SummarySection = ({ appointment, userType }: SectionProps) => {
  const dispatch = useAppDispatch();
  const query = useQuery();
  const history = useHistory();
  const [showCardVerificationModal, setShowCardVerificationModal] =
    useState(false);
  const datetime = moment(appointment.start).tz(appointment.timezone);
  const showVerificationResults = query.get(QueryParams.ShowVerificationResult);

  useEffect(() => {
    if (showVerificationResults) {
      const success =
        appointment.lastPaymentIntent?.status ===
        PaymentIntentStatus.RequiresCapture;
      enqueueSnackbar({
        message: success
          ? 'Your card was successfully authorized'
          : 'Error verifying your credit card',
        variant: success ? 'success' : 'error',
      });
    }
  }, [showVerificationResults]);

  const handleOpenVerificationModal = () => {
    window.addEventListener(
      'message',
      async function (e) {
        if (
          e.origin === 'https://oleksiak.s3.us-west-2.amazonaws.com' &&
          e.data === 'card-verification-complete'
        ) {
          setShowCardVerificationModal(false);
          await dispatch(fetchAppointment(appointment.id));
          history.push(
            window.location.pathname +
              window.location.search +
              `&${QueryParams.ShowVerificationResult}=true`
          );
        }
      },
      false
    );
    setShowCardVerificationModal(true);
  };

  return (
    <>
      <div className={SECTION_CLASSES}>
        <div>
          <h2>
            {appointment.activity.name} with{' '}
            {appointment.instructor.displayName}
          </h2>
          <div className="text-base font-normal text-gray-900">
            {datetime.format(DATE_FMT.DOW_MONTH_D)} at{' '}
            {datetime.format(DATE_FMT.TIME_A)} for {appointment.numParticipants}{' '}
            {appointment.activity.clientDescription
              .toLowerCase()
              .pluralize(appointment.participants.length)}
          </div>
          {userType === UserType.Client &&
            !appointment.cancelled &&
            appointment.timeFrame === TimeFrame.Outside48 &&
            [
              PaymentIntentStatus.RequiresPaymentMethod,
              PaymentIntentStatus.RequiresAction,
            ].includes(appointment.lastPaymentIntent?.status) && (
              <Callout
                className="my-4"
                type="error"
                title="Payment authorization issue"
              >
                <p className="my-1 leading-relaxed">
                  {!appointment.lastPaymentIntent.redirectUrl ? (
                    <>
                      To avoid lesson cancellation, resolve any card issues
                      before the next authorization attempt or{' '}
                      <span
                        className="link"
                        onClick={() =>
                          history.push(
                            SHARED_ROUTES.ASSISTANCE.nav(
                              appointment.id,
                              'payment'
                            )
                          )
                        }
                      >
                        change your payment method
                      </span>
                    </>
                  ) : (
                    <>
                      To avoid lesson cancellation,{' '}
                      <span
                        className="link"
                        onClick={handleOpenVerificationModal}
                      >
                        verify your identity
                      </span>{' '}
                      with your card issuer or{' '}
                      <span
                        className="link"
                        onClick={() =>
                          history.push(
                            SHARED_ROUTES.ASSISTANCE.nav(
                              appointment.id,
                              'payment'
                            )
                          )
                        }
                      >
                        change your payment method
                      </span>
                    </>
                  )}
                </p>
              </Callout>
            )}
          <AppointmentCancellationDetails appointment={appointment} />
        </div>
        <div className="space-y-2">
          <div className="labeled-icon">
            <FacilityIcon width={24} />
            <span className="text-gray-900">
              {appointment.facility.displayName}
            </span>
          </div>
          {/* In lieu of hosts not having Client and Instructor sections */}
          {userType === UserType.Host && (
            <>
              <div className="labeled-icon h-">
                <Avatar
                  src={appointment.instructor.avatar}
                  className=""
                  diameter={6}
                />
                <span className="text-gray-900">
                  {appointment.instructor.fullName}
                </span>
              </div>
              <div className="labeled-icon">
                <AccountIcon width={24} />
                <span className="text-gray-900">
                  {appointment.client.fullName}
                </span>
              </div>
              <div className="labeled-icon">
                <BookmarkIcon width={24} />
                <span className="text-gray-900">
                  {appointment.facilityNumAppointment.toOrdinal()}{' '}
                  {appointment.activity.name.toLowerCase()}
                </span>
              </div>
            </>
          )}
          {appointment.booked && (
            <div className="labeled-icon">
              <ClockIcon width={24} />
              <span className="text-gray-900">
                Booked on{' '}
                {moment(appointment.booked).format(
                  DATE_FMT.MONTH_D_YEAR_TIME_A
                )}
              </span>
            </div>
          )}
          {appointment.facility.accessDirectionsUrl !== undefined && (
            <ListButton
              title="View access details"
              to={appointment.facility.accessDirectionsUrl}
              icon={<MapIcon width={24} />}
              disabled={
                appointment.cancelled ||
                appointment.timeFrame > TimeFrame.HasEnded
              }
            />
          )}
          {!appointment.cancelled &&
            userType !== UserType.Client &&
            appointment.facility.instructorNotes &&
            appointment.timeFrame < TimeFrame.HasEnded && (
              <div className="p-4 text-gray-700 rounded-lg bg-background">
                {appointment.facility.instructorNotes}
              </div>
            )}
        </div>
      </div>
      {/* https://docs.stripe.com/payments/3d-secure/authentication-flow#render-iframe */}
      {userType === UserType.Client && (
        <Modal name="Verify Card" open={showCardVerificationModal}>
          <div>
            {appointment.lastPaymentIntent?.redirectUrl && (
              <iframe
                src={appointment.lastPaymentIntent.redirectUrl}
                width="390"
                height="400"
              />
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

export default SummarySection;
